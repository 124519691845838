<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">featured floor plans</div>
        <div class="module-preview--contents">
            <div class="text-wrapper">
                <span class="font-inter font-600 text-lg">{{ getValue('sectionTitle') }}</span>            
                <p class="mt-4 text-sm pr-12">{{ getValue('description') }}</p>
            </div>
            <div class="floorplans-list">
                <ul>
                    <li v-for="(plan,index) in featuredFloorplans" :key="index">                        
                        {{ plan }}
                    </li>
                </ul>
            </div>
        </div>
    </div>     
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ],
        data: () => {
            return {
                featuredFloorplans: []
            }
        },
        computed: {
            ...mapGetters({
                getFloorplanByName: 'floorplans/getFloorplanByName'
            }),
        },
        created() {
            let floorplans = JSON.parse(this.getValue('floorplans'));
            floorplans.forEach( item => {
                this.featuredFloorplans.push(item)
            });
        }
    }
</script>
<style scoped>   
    .module-preview--contents{
        grid-template-columns: 1.5fr 1fr;
    } 
    
    .floorplans-list li{      
        @apply text-base font-500;          
        color: #1A5984;
    }
    .floorplans-list li + li{
        @apply mt-2;
    }
</style>